// src/pages/CourtListPage.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CourtForm from "./CourtForm";
import {AppBaseUrl} from '../appdata/appData';
import AutohideSnackbar from './autohideSnackbar';
import AppGlobalStaticData from '../appdata/appStaticData';

const CourtListPage = () => {
  const [courts, setCourts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedCourtId, setSelectedCourtId] = useState(null);
  const [snackbarProperties,  setSnackbarProperties] = useState({
    duration:0, 
    message:'', 
    serverity:''
  });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
  useEffect(() => {
    if (snackbarProperties.message) {
      setSnackbarOpen(true);
    }
  }, [snackbarProperties.message]);
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const globalData = new AppGlobalStaticData();


  useEffect(() => {
    fetchCourts();
  }, []);

  const fetchCourts = async () => {
    try {
      const response = await axios.get(
        `${AppBaseUrl}/api/v1/court/list?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`
      );
      if (response.data.status === "Success") {
        const courtListWithSno = response.data.data.map((court, index) => ({
          sno: index + 1,
          ...court,
        }));
        setCourts(courtListWithSno);
        setSnackbarProperties({
          duration:globalData.Duration, 
          message:globalData.CourtListSuccess, 
          serverity:globalData.SeveritySuccess
        });
      } else {
       
        setSnackbarProperties({
          duration:globalData.Duration, 
          message:globalData.CourtListFailed, 
          serverity:globalData.SeverityError
        });
        console.error("Failed to fetch courts");
      }
    } catch (error) {
      setSnackbarProperties({
        duration:globalData.Duration, 
        message:globalData.CourtListFailed, 
        serverity:globalData.SeverityError
      });
      console.error("Error fetching courts:", error);

    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (id) => {
    setSelectedCourtId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCourtId(null);
    // Refresh court list after closing modal
    fetchCourts();
  };

  const handleEdit = (id) => {
    setSelectedCourtId(id);
    setOpen(true);
  };

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .delete(
        `${AppBaseUrl}/api/v1/court/${id}/delete?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`
      )
      .then((response) => {
        if (response.data.status === "Success") {
          setSnackbarProperties({
            duration:globalData.Duration, 
            message:globalData.DeleteCourtSuccess, 
            serverity:globalData.SeveritySuccess
          });
        } else {
          setSnackbarProperties({
            duration:globalData.Duration, 
            message:globalData.DeleteCourtFailed, 
            serverity:globalData.SeverityError
          });
          console.error("Failed to fetch court details");
        }
      })
      .catch((error) => {
        setSnackbarProperties({
          duration:globalData.Duration, 
          message:globalData.DeleteCourtFailed, 
          serverity:globalData.SeverityError
        });
        console.error("Error fetching court details:", error);
      })
      .finally(() => {
        setLoading(false);
        fetchCourts();
      });
  };

  const columns = [
    { field: "sno", headerName: "S.No", width: 90, sortable: false },
    { field: "CourtName", headerName: "Court Name", width: 200 },
    { field: "Place", headerName: "Place", width: 200 },
    { field: "orderId", headerName: "Order", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEdit(params.id)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.id)} color="secondary">
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          Court List
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen(null)}
        >
          Create Court
        </Button>
      </Box>

      <div style={{ height: 400, width: "100%", margin: "auto" }}>
        <DataGrid rows={courts} columns={columns} pageSize={5} />
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {selectedCourtId ? "Edit Court" : "Create Court"}
        </DialogTitle>
        <DialogContent>
          <CourtForm id={selectedCourtId} onClose={handleClose} />
        </DialogContent>
      </Dialog>
      <AutohideSnackbar open={snackbarOpen}
        duration={snackbarProperties.duration}
        message={snackbarProperties.message}
        severity={snackbarProperties.serverity}
        handleClose={handleSnackbarClose} ></AutohideSnackbar>
    </Container>
  );
};

export default CourtListPage;
