import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import UserForm from "./UserForm";
import {AppBaseUrl} from '../appdata/appData';
import AutohideSnackbar from './autohideSnackbar';
import AppGlobalStaticData from '../appdata/appStaticData';

const UserList = () => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [snackbarProperties,  setSnackbarProperties] = useState({
    duration:0, 
    message:'', 
    serverity:''
  });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
  useEffect(() => {
    if (snackbarProperties.message) {
      setSnackbarOpen(true);
    }
  }, [snackbarProperties.message]);
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const globalData = new AppGlobalStaticData();

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchUserList = async () => {
    try {
      const response = await axios.get(
        `${AppBaseUrl}/api/v1/user/list?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`
      );
      if (response.data.status === "Success") {
        const userListWithSNO = response.data.data.map((user, index) => ({
          sno: index + 1,
          ...user,
        }));
        setSnackbarProperties({
          duration:globalData.Duration, 
          message:globalData.UserListSuccess, 
          serverity:globalData.SeveritySuccess
        });
        setUserList(userListWithSNO);
      } else {
        setSnackbarProperties({
          duration:globalData.Duration, 
          message:globalData.UserListFailed, 
          serverity:globalData.SeverityError
        });
      }
    } catch (error) {
      setSnackbarProperties({
        duration:globalData.Duration, 
        message:globalData.UserListFailed, 
        serverity:globalData.SeverityError
      })
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (id) => {
    setSelectedUserId(id);
    setOpen(true);
  };

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .delete(
        `${AppBaseUrl}/api/v1/user/${id}/delete?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`
      )
      .then((response) => {
        if (response.data.status === "Success") {
          setSnackbarProperties({
            duration:globalData.Duration, 
            message:globalData.DeleteUserSuccess, 
            serverity:globalData.SeveritySuccess
          });
        } else {
          setSnackbarProperties({
            duration:globalData.Duration, 
            message:globalData.DeleteUserFailed, 
            serverity:globalData.SeverityError
          });
        }
      })
      .catch((error) => {
        setSnackbarProperties({
          duration:globalData.Duration, 
          message:globalData.DeleteUserFailed, 
          serverity:globalData.SeverityError
        });
      })
      .finally(() => {
        setLoading(false);
        fetchUserList();
      });
  };

  const handleOpen = () => {
    // Handle open logic
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUserId(null);
    // Refresh court list after closing modal
    fetchUserList();
  };

  const columns = [
    { field: "sno", headerName: "S.NO", width: 90, sortable: false },
    { field: "name", headerName: "Name", width: 180, sortable: true },
    { field: "email", headerName: "Email", width: 180, sortable: true },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (param) => (
        <Box>
          <IconButton onClick={() => handleEdit(param.id)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(param.id)} color="secondary">
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
        <Typography variant="h4" gutterBottom>
          User List
        </Typography>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add User
        </Button>
      </Box>
      <div style={{ height: 400, width: "100%", margin: "auto" }}>
        <DataGrid rows={userList} columns={columns} pageSize={5} />
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{selectedUserId ? 'Edit User' : 'Create User'}</DialogTitle>
        <DialogContent>
          <UserForm id={selectedUserId} onClose={handleClose} />
        </DialogContent>
      </Dialog>
      <AutohideSnackbar open={snackbarOpen}
        duration={snackbarProperties.duration}
        message={snackbarProperties.message}
        severity={snackbarProperties.serverity}
        handleClose={handleSnackbarClose} ></AutohideSnackbar>
    </Container>
    
  );
};

export default UserList;
