import React, { useEffect } from "react";
import CaseList from '../components/CaseList';
import {
  Container,
  Typography,
  Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const EditCaseListPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get('auth_token');
    if (!token) {
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  }, [navigate]);

  const today = new Date();
  const dd = String(today.getDate() - 1).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  const date = `${yyyy}/${mm}/${dd}`;
  const casestatus = [0];
  const startDate = '';

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          Pending Case List
        </Typography>
      </Box>
      <CaseList Type={"Edit"} startdate={startDate} enddate={date} casestatusParam={casestatus} />
    </Container>
  );
};

export default EditCaseListPage;
