import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import {Alert} from '@mui/material';

export default function AutohideSnackbar({open,message, duration,severity,handleClose}) {
 // const [open, setOpen] = React.useState(true);
  return (
    <div>
      <Snackbar open={open}  autoHideDuration={duration} onClose={handleClose} anchorOrigin={{vertical:'top', horizontal:'center'}}>
        <Alert onClose={handleClose}  severity={severity} variant="filled" sx={{ width: '100%' }}>{message} </Alert>
    </Snackbar>
    </div>
  );
}
