import React, { useEffect } from "react";
import CaseList from "../components/CaseList";
import {
    Container,
    Typography,
    Box
  } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const CaseListPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get('auth_token');
    if (!token) {
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  }, [navigate]);

  const enddate = '';
  // const today = new Date();
  // const dd = String(today.getDate()).padStart(2, "0");
  // const mm = String(today.getMonth() + 1).padStart(2, "0");
  // const yyyy = today.getFullYear();

  // const date = '';
  // const date = `${yyyy}/${mm}/${dd}`;
  const casestatus = [0];

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          Case List
        </Typography>
      </Box>
      <CaseList Type={"List"} startdate={enddate} enddate={enddate} casestatusParam={casestatus} />
    </Container>
  );
};

export default CaseListPage;
