// src/components/Login.js
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../assest/image/login-page-bg.jpg";
import axios from "axios";
import Cookies from 'js-cookie';

import AutohideSnackbar from './autohideSnackbar';
import {AppBaseUrl} from '../appdata/appData';
import AppGlobalStaticData from '../appdata/appStaticData';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const globalData = new AppGlobalStaticData(); // Create an instance of AppGlobalStaticData

  useEffect(() => {
    const token = Cookies.get('auth_token');
    if (token) {
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  }, [navigate]);

  const [snackbarProperties,  setSnackbarProperties] = useState({
      duration:0, 
      message:'', 
      serverity:''
    });
  
   const [snackbarOpen, setSnackbarOpen] = useState(false);
 useEffect(() => {
   if (snackbarProperties.message) {
     setSnackbarOpen(true);
   }
 }, [snackbarProperties.message]);
 
 const handleSnackbarClose = () => {
   setSnackbarOpen(false);
 };

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent default form submission
    try {
      const response = await axios.post(
        `${AppBaseUrl}/api/v1/user/login?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.status === 200) {
        Cookies.set('auth_token', response.data.token, { expires: 1 });
        setSnackbarProperties({
          duration:globalData.Duration, 
          message:globalData.LoginSuccess, 
          serverity:globalData.SeveritySuccess
        });
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        setSnackbarProperties({
          duration:globalData.Duration, 
          message:globalData.LoginFailed, 
          serverity:globalData.SeverityError
        });
      }
    } catch (error) {

      setSnackbarProperties({
        duration:globalData.Duration, 
        message:globalData.LoginFailed, 
        serverity:globalData.SeverityError
      });
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container
        maxWidth="sm"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Paper
          elevation={3}
          style={{ padding: "20px", width: "100%", boxSizing: "border-box" }}
        >
          <Typography variant="h4" gutterBottom align="center">
            Login
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              label="Username"
              fullWidth
              margin="normal"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              fullWidth
              margin="normal"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: "20px" }}
              type="submit"
            >
              Login
            </Button>
          </form>
        </Paper>
       <AutohideSnackbar open={snackbarOpen}
         duration={snackbarProperties.duration}
         message={snackbarProperties.message}
         severity={snackbarProperties.serverity}
         handleClose={handleSnackbarClose} ></AutohideSnackbar>
      </Container>
    </Box>
  );
};

export default Login;
