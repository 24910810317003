// src/components/Sidebar.js
import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import GavelIcon from '@mui/icons-material/Gavel';
import HomeIcon from '@mui/icons-material/Home';
import BlindsClosedOutlinedIcon from '@mui/icons-material/BlindsClosedOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import Divider from '@mui/material/Divider';


const Sidebar = ({ open, onClose }) => {
  const navigate = useNavigate();
  const drawerWidth = 240;

  const OrangeColor = {
    color: "#F64E31"
  };
  const menuList = [
    { name: "Home", url: "/", icon: <HomeIcon style={OrangeColor}/> },
    { name: "Case List", url: "/case/list", icon: <ListAltOutlinedIcon style={OrangeColor} />},
    { name: "Pending Case", url: "/case/edit",icon: <PendingActionsOutlinedIcon style={OrangeColor} /> },
    { name: "Disposed/ Decreed", url: "/case/disposed-decreed",icon: <BlindsClosedOutlinedIcon style={OrangeColor}/> },
    { name: "Users", url: "/users", icon: <PersonOutlineOutlinedIcon style={OrangeColor}/> },
    { name: "Courts", url: "/courts", icon: <GavelIcon style={OrangeColor}/> },
  ];

  const handleClick = (url) => {
    navigate(url);
    onClose();
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{ width: drawerWidth, "& .MuiDrawer-paper": { width: drawerWidth } }}
    >
      <List>
        {menuList.map((menu, index) => (
          <>
           <ListItem key={index} onClick={() => handleClick(menu.url)}>
            <ListItemButton>
              <ListItemIcon>
                {menu.icon }
              </ListItemIcon>
              <ListItemText  style={OrangeColor} primary={menu.name} />
            </ListItemButton>
          </ListItem>
          <Divider/>
          </>
         
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
